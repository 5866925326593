import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import VueVideoPlayer from 'vue-video-player'
import 'video.js/dist/video-js.css'


import '../src/lang/index.scss';


import i18n from './locals/index'


import axios from 'axios'

// // 设置基础访问路径
// axios.defaults.baseURL='https://127.0.0.1:8080/bms/management'

const version = '1.0.32';

console.log(version)

const app=createApp(App)
 
app
  .use(store)
  .use(i18n)
  .use(router)
  .use(ElementPlus)
  .use(VueVideoPlayer)
  .mount('#app')
 
// app.config.globalProperties.$axios = axios


